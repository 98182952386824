
            @import 'src/styles/mixins.scss';
        
.mainContainer {
    position: relative;

    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: -2;
        background-color: #fffaef;
    }
}
